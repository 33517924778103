import React from 'react';
import Slider from 'react-slick';
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Typed from 'react-typed';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

const mock = [
  {
    title: 'Pipy',
		logo:['/img/pipy.png','/img/pipy-white.png'],
    desc: [
			`<div><b>Pipy</b> is a high performance programmable proxy for the cloud, edge and IoT. Its core is written in <b>C++</b> with excellent cross-platform capability. It is both high performance and low resource. Pipy comes with a built-in <b>JavaScript</b> engine that allows easy custom logic implementation with the simple JS syntax, greatly reducing the complexity in <b>high-performance</b> network programming.</div>
<div>It is suitable for a variety of hardware architectures including <b>x86</b>, <b>ARM64</b>, <b>Loongson</b> and <b>RISC-V</b>. It is also compatible with various other operating systems besides <b>Linux</b>.</div>`
		],
		btn:'Pipy Home',
		path:'/pipy',
		prefix: '',
		words: [
			'High-performance programmable proxy', 
			'Developed in C++',
			'60K lines of code',
			'Built-in JavaScript engine: PipyJS',
			'MIT-like license'
		]
  },
// FLB is a SaaS-based one stop batteries-included solution for your cloud native needs. It is offered on cloud service providers marketplace like AWS and Azure. FLB provides an out of the box 
// * Service Mesh Interface (SMI) compatible service mesh
// * Software Load balancer
// * API Management & Gateway
// * Multi-tenancy
// * Kubernetes Multi-cluster support
// * Multi-Systems Integrations
// * Full observability support
// * Dashboards
// * Admin consoles with intuitive interfaces
// * and more
  {
    title: 'FLB.',
		// logo:['/img/flomesh.png','/img/flomesh-white.png'],
    desc: [
			`The <b>FLB (Flomesh Software Load Balancer)</b> is a software-based application load balancing solution that is a reliable, efficient, and scalable solution to offer both <b>Layer 4</b> and <b>Layer 7</b> load-balancing capabilities. 
<b>FLB</b> has the flexibility to operate in different settings such as physical and virtual machines, cloud hosts, and k8s container platforms.
<ul><li> BGP based L4 Load Balancing</li>
<li> Multi-protocol L7 load balancing</li>
<li> API granular load balancing and policy management</li>
<li> Client load balancing for microservices</li>
<li> Comprehensive web server</li>
<li> DNS load balancing</li>
<li> eBPF Enhanced Networking Capabilities</li>
<li> Multi-tenancy support</li>
<li> Graphical Web Console</li>
</ul>`,
		],
		btn:'FLB Home',
		path:'/flb',
		prefix: '',
		words: [
			'Deploy to bare metal, virtual machines or Kubernetes', 
			'Comprehensive API management',
			'Reliable, Efficient, and Scalable L4/7 load-balancing',
			'Client-side Load Balancing'
		]
  },
  {
    title: 'FSM.',
		logo:['/img/fsm.jpg','/img/fsm.jpg'],
    desc: [
			`<b>Flomesh Service Mesh (FSM)</b> stands out as a <b>cloud-native</b> service mesh solution renowned for its lightweight design and adaptability, catering to both <b>x86</b> and <b>ARM</b> architectures. <b>FSM</b> excels in seamlessly managing traffic for deployments while ensuring the security of <b>service-to-service</b> communication through mutual TLS. Moreover, it empowers users to define and enforce finely-tuned access control policies, providing valuable insights into application metrics for effective debugging and service monitoring, thanks to its robust observability features.
What sets <b>FSM</b> apart is its effortless integration with external certificate management services through a pluggable interface. Simplifying the onboarding process for applications onto the mesh, <b>FSM</b> achieves this through the automatic sidecar injection of the <a href="https://flomesh.io/pipy" target="_blank">Pipy</a> proxy. 
Noteworthy is <b>FSM</b>'s support for Multi-cluster <b>Kubernetes</b>, facilitated by the implementation of the <a href="https://github.com/kubernetes-sigs/mcs-api" target="_blank">MCS-API</a> and
support of <b>Kubernetes Gateway API</b> via its own implementation of <a href="https://gateway-api.sigs.k8s.io/" target="_blank">Kubernetes Gateway API</a> specification.
<b>FSM</b> package includes built-in controllers for <b>Ingress/Egress</b> and the <b>Gateway API</b>, along with support for protocols such as <b>MQTT</b>.`
		],
		btn:'FSM Home',
		path:'/fsm',
		prefix: '',
		words: [
			'Open source',
			'Supports Kubernetes Gateway Api',
			'K8s Multi-cluster discovery & access',
			'Community driven',
			'Easy to install',
			'Easy to extend',
			'ARM Ready',
			'Built for Cloud and Edge',
			'Optimized for resource constraint environments',
			'Works on multiple architectures',
			'Programmable proxy Pipy built-in',
			'Out of the box support for multiple protocols',
			'Full observability support',
			'Comes with Dashboard',
		]
  },
  {
    title: 'ZTM.',
		logo:['/img/ztm.png','/img/ztm.png'],
    desc: [
			`<div>
<b>ZTM HomeConnect</b>, powered by the open-source <a href="https://github.com/flomesh-io/ztm" target="_blank">ZTM</a> project, enables you to securely connect to your personal files, media, and devices without any geographical or network restrictions. Whether you're traveling for work and need to access important files from your home computer, or you want to share cherished photos with friends and family without relying on big-tech social networks, <b>ZTM HomeConnect</b> provides the seamless connectivity you need.
With <b>ZTM HomeConnect</b>, you can:
<ul><li> Access your home network from anywhere.</li>
<li> Share files privately.</li>
<li> Private, secure communication.</li>
</ul></div>
<b>ZTM HomeConnect</b> leverages the core capabilities of <b>ZTM</b>, including encrypted communication channels, certificate-based authentication, and robust service discovery. Stay connected and in control, no matter where you are, with the reliable access that <b>ZTM HomeConnect</b> delivers.
`,
			// '<b>Flomesh GUI</b> is an all-in-one console utilizing the <b>DB/Strapi/Vue</b> three-tier architecture. It consists of modules for service mesh, software load balancing, API management, health check, operation and maintenance center, etc. It also provides basic system management functionality.',
			// 'The product supports <b>multi-tenant</b>, <b>multi-cluster</b> and <b>multi-registry</b> service traffic management, provides comprehensive observability and a unified view for services. With its API management, plenty of out-of-the-box pluggable policies are ready for use.'
		],
		btn:'ZTM Home',
		path:'/ztm',
		prefix: '',
		words: [
			'Zero Trust Mesh', 
			'Peer to Peer',
			'Open Source',
			'Network Attached Storage'
		],
  },
 //  {
 //    title: 'Hardware Compatibility',
 //    desc: [
	// 		'All products support multi-use computing architectures, including <b>x86, ARM64, Loongson</b> and <b>RISC-V</b>, to meet the demand for high cost performance in the cloud, as well as adapting to diverse computing architectures being used at the edge.'
	// 	],
	// },
 //  {
 //    title: 'Platform Compatibility',
 //    desc: [
	// 		'All products run in <b>Kubernetes containers</b>, <b>virtual machines</b>, <b>physical machines</b> and other environments. Public, private and hybrid clouds are all supported.'
	// 	],
	// },
];

const Products = ({initialSlide}) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
	console.log(initialSlide)
  const sliderOpts = {
    dots: !isMd,
		initialSlide,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMd,
  };
  const [slider, setSlider] = React.useState(null);
	if(slider){
		if(initialSlide<4){
			slider.slickGoTo(initialSlide);
		}
	}
  return (
    <Box
      sx={{
        position: 'relative',
        '&::after': {
          position: 'absolute',
          content: '""',
          width: '52%',
          zIndex: 1,
          top: 0,
          left: 0,
          height: '100%',
          backgroundSize: '18px 18px',
          backgroundImage: `radial-gradient(${alpha(
            theme.palette.primary.dark,
            0.4,
          )} 20%, transparent 20%)`,
          opacity: 0.2,
        },
      }}
    >
      <Box position={'relative'} zIndex={2}>
        <Box
          sx={{
            height: { xs: 'auto', md: 1 },
            '& .slick-slide img': {
              objectFit: 'cover',
            },
            '& .slick-list, & .slick-slider, & .slick-track, & .slick-slide > div': {
              height: { xs: 'auto', md: 1 },
            },
            '& .slick-prev, & .slick-next': {
              zIndex: 2,
              bottom: 0,
              top: '0%',
              left: '120px',
              transform: `translate(-100%, calc(-100% - ${theme.spacing(2)}))`,
              marginLeft: theme.spacing(-2),
              width: 32,
              height: 32,
              '&:before': {
                fontSize: 32,
                color: 'primary.main',
              },
            },
            '& .slick-prev': {
              marginLeft: theme.spacing(-7),
            },
          }}
        >
          <Slider ref={slider => setSlider(slider)} {...sliderOpts}>
            {mock.map((item, i) => (
              <Box key={i}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    boxShadow: 0,
                    background: 'transparent',
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: { sx: 0, md: 2 },
                    }}
                  >
                    <Box maxWidth={{ xs: 1, sm: '50%' }} sx={{
                    	minHeight: '250px'
                    }}>
										
                    	<Typography
                    	  variant="h3"
                    	  color="text.primary"
                    	  gutterBottom
                    	  sx={{
                    	    fontWeight: 700,
                    	  }}
                    	>
												{ item.logo && (
													<Box
														display={'inline-block'}
														component={'span'}
														height={{ xs: 70, md: 70 }}
														sx={{
															verticalAlign: 'middle',
															position: 'relative',
															marginRight: '20px'
														}}
													>
														<Box
															component={'img'}
															sx={{
																verticalAlign: 'middle'
															}}
															src={
																mode === 'light'
																	? item.logo[0]
																	: item.logo[1]
															}
															height={1}
														/>
													</Box>
												)}
												<Box
													display={'inline-block'}
													sx={{
														verticalAlign: 'middle'
													}}
												>
													{item.title}
												</Box>
                    	  <br />
												{item.prefix?(
													<Typography
														color="text.primary"
														component={'span'}
														variant="h5"
														sx={{
															marginRight: '10px',
														}}
													>
													{item.prefix}
													</Typography>
												):null}
												{item.words?(
													<Typography
														color={'primary'}
														component={'span'}
														variant="h5"
													>
														<Typed
															strings={item.words}
															typeSpeed={80}
															loop={true}
														/>
													</Typography>
												):null}
                    	</Typography>
											{item.desc.map((desc, j) => (
												<Box
													key={j}
													variant="h7"
													component="p"
													color="text.secondary"
													sx={{ fontWeight: 400,marginBottom:'15px' }}
												>
													<div dangerouslySetInnerHTML={{__html:desc}}></div>
												</Box>
											))}
                    	<Box
                    	  display="flex"
                    	  flexDirection={{ xs: 'column', sm: 'row' }}
                    	  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                    	  marginTop={4}
                    	>
												{item.btn?(
													<Button
														component={Link}
														variant="contained"
														color="primary"
														size="large"
														fullWidth={isMd ? false : true}
														to={RouterLink(item.path)}
													>
														{item.btn}
													</Button>
												):null}
                    	</Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

Products.propTypes = {
  initialSlide: PropTypes.number
};
export default Products;
